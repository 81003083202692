<template>
  <div class="indexStyle">
        <div class="head_img" :style="`height:${banner_height}px`"></div>
        <div class="trait">
            <div class="h2">功能特征</div>
            <div class="content">峰盛科技数字电商-在线商城系统</div>
            <div class="domain">
                <ul>
                    <li v-for="(item,index) in traitList" :key="index">
                        <img :src="item.img" alt="">
                        <div class="title">{{item.title}}</div>
                        <div class="content">
                            <p>{{item.content}}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="trade">
            <div class="h2" v-show="tradeShow">更多行业适用的小程序</div>
            <div class="content" v-show="tradeShow">我们为不同行业商家提供小程序能力</div>
            <ul v-show="tradeShow">
                <li v-for="(item,index) in tradeList" :key="index">
                    <div>
                        <img :src="item.img" alt="">
                        <p>{{item.title}}</p>
                    </div>
                    
                </li>
            </ul>
        </div>
        <userSide :userShow="userShow" :list="mobileList" />
        <computerCase :deskShow="deskShow" :list="caseList"  :title="'后台截图预览'" :bgColor="'#fff'" />
        <correlationCase :caseShow="caseShow" :caseList="hotelList" />
</div>
</template>

<script>
import correlationCase from '@/components/correlationCase'
import userSide from '@/components/userSide'
import computerCase from '@/components/computerCase'

export default {
    components:{correlationCase,computerCase,userSide},
    data(){
        return{
            traitList:[
                {
                    img:require('@/assets/image/applet/icon12.png'),
                    title:'官网小程序',
                    content:'多种模式轻松搭建，一键生成，快速上线',
                },
                {
                    img:require('@/assets/image/applet/icon1.png'),
                    title:'电商小程序',
                    content:'拖拽小程序商城，移动电商首选',
                },
                {
                    img:require('@/assets/image/applet/icon2.png'),
                    title:'预约小程序',
                    content:'小程序预约，到店服务'
                },
                {
                    img:require('@/assets/image/applet/icon3.png'),
                    title:'门店小程序',
                    content:'连锁超市、线下分销、同城平台',
                },
                {
                    img:require('@/assets/image/applet/icon4.png'),
                    title:'餐饮小程序',
                    content:'到店支付、排队点餐、小票打印',
                },
                {
                    img:require('@/assets/image/applet/icon5.png'),
                    title:'外卖小程序',
                    content:'小程序线上下单，线下送达'
                },
                {
                    img:require('@/assets/image/applet/icon6.png'),
                    title:'分销小程序',
                    content:'二级返佣、无限裂变、低成本快速获客',
                },
                {
                    img:require('@/assets/image/applet/icon7.png'),
                    title:'营销小程序',
                    content:'小程序营销，传播利器'
                },
                {
                    img:require('@/assets/image/applet/icon8.png'),
                    title:'拖拽制作',
                    content:'灵活可视化拖拽制作，即可生成小程序'
                },
                {
                    img:require('@/assets/image/applet/icon9.png'),
                    title:'即买即用',
                    content:'多款小程序模板，一键生成、快速上线',
                },
                {
                    img:require('@/assets/image/applet/icon10.png'),
                    title:'功能丰富',
                    content:'官网、电商、多门店，使用多种小程序场景',
                },
                {
                    img:require('@/assets/image/applet/icon11.png'),
                    title:'深入行业',
                    content:'面对电商、餐饮等多个行业深度开发',
                },
                {
                    img:require('@/assets/image/applet/icon13.png'),
                    title:'线下引流',
                    content:'基于LBS地理位置，精准推荐小程序',
                },
                {
                    img:require('@/assets/image/applet/icon14.png'),
                    title:'会员积累',
                    content:'小程序卡券派发，提升会员沉淀与活跃',

                },
                {
                    img:require('@/assets/image/applet/icon15.png'),
                    title:'降低成本',
                    content:'在线预约、在线点单、在线支付免除繁杂人工操作',
                },
                {
                    img:require('@/assets/image/applet/icon16.png'),
                    title:'专属',
                    content:'1对1专属定制顾问'
                },

            ],
            tradeList:[
                {
                    img:require('@/assets/image/applet/icon18.png'),
                    title:'餐饮',
                },
                {
                    img:require('@/assets/image/applet/icon20.png'),
                    title:'电商',
                },
                {
                    img:require('@/assets/image/applet/icon24.png'),
                    title:'健身',
                },
                {
                    img:require('@/assets/image/applet/icon21.png'),
                    title:'美业',
                },
                {
                    img:require('@/assets/image/applet/icon28.png'),
                    title:'婚庆',
                },
                {
                    img:require('@/assets/image/applet/icon25.png'),
                    title:'KTV',
                },
                {
                    img:require('@/assets/image/applet/icon17.png'),
                    title:'酒吧',
                },
                {
                    img:require('@/assets/image/applet/icon19.png'),
                    title:'教育',
                },
                {
                    img:require('@/assets/image/applet/icon22.png'),
                    title:'零售',
                },
                {
                    img:require('@/assets/image/applet/icon23.png'),
                    title:'酒店',
                },
                {
                    img:require('@/assets/image/applet/icon26.png'),
                    title:'康体SPA',
                },
                {
                    img:require('@/assets/image/applet/icon27.png'),
                    title:'汽车',
                },
            ],
            //美容
            hotelList:[
                {
                    img:require('@/assets/image/case/13.png'),
                    title:'美容系统',
                    content1:'美莱医疗美容',
                    content2:'美容互联网解决方案，构建线上预约的新消费模式'
                },
                {
                    img:require('@/assets/image/case/14.png'),
                    title:'美容系统',
                    content1:'亿念美美容',
                    content2:'专注美业saas系统，线上线下营销会员拓客溜客'
                },
                {
                    img:require('@/assets/image/case/15.png'),
                    title:'美容系统',
                    content1:'毛大夫医疗整形',
                    content2:'美容系统数据哭稳定、安全、集成数据库'
                },
                {
                    img:require('@/assets/image/case/16.png'),
                    title:'美容系统',
                    content1:'Dr.Arrivo美容液',
                    content2:'店务+报表+会员一体化，提供贴合美容行业方案'
                },
            ],
            mobileList:[
                require('@/assets/image/applet/slideshow1.png'),
                require('@/assets/image/applet/slideshow2.png'),
                require('@/assets/image/applet/slideshow3.png'),
                require('@/assets/image/applet/slideshow4.png'),
            ],
            caseList:[
                require('@/assets/image/applet/case1.png'),
                require('@/assets/image/applet/case2.png'),
                require('@/assets/image/applet/case3.png')
            ],
            caseShow:false,
            userShow:false,
            deskShow:false,
            tradeShow:false,
            windowWidth:document.documentElement.clientWidth,
            banner_height: document.documentElement.clientWidth > 1024 ?
            document.documentElement.clientWidth * 0.21 : document.documentElement.clientWidth * 0.29,
        }
    },
     mounted(){
        window.addEventListener('scroll', this.handleScroll, true);  // 监听（绑定）滚轮滚动事件
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                this.windowWidth = window.fullWidth // 宽
                if(this.windowWidth >= 1024){
                    this.banner_height = this.windowWidth * 0.21
                }else{
                    this.banner_height = this.windowWidth * 0.29
                }
            })()
        }
        if(this.windowWidth <= 1024){
            this.tradeShow = true
            this.userShow = true
            this.deskShow = true
            this.caseShow = true
        } 
    },
    methods:{
         // 滚动监听 · 动画播放
        handleScroll(){ 
            if(this.windowWidth <= 1024)return
                // 页面滚动距顶部距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || 
                    document.body.scrollTop
            
            let trade = document.getElementsByClassName('trade')
            let trade_h2 = trade[0].getElementsByClassName('h2')
            let trade_content = trade[0].getElementsByClassName('content')
            let trade_li = trade[0].getElementsByTagName('li')

            let user = document.getElementsByClassName('user')
            let user_h2 = user[0].getElementsByClassName('h2')
            let user_content = user[0].getElementsByClassName('content')
            let user_slide = user[0].getElementsByClassName('slide')
            
            let desk = document.getElementsByClassName('desk')
            let slidShow = document.getElementsByClassName('slidShow')
           //开发案例
            let Case = document.getElementsByClassName('case')
            let Case_h2 = Case[0].getElementsByClassName('h2')
            let Case_swiper = Case[0].getElementsByClassName('case-swiper-container')

            if(scrollTop > (trade[0].getBoundingClientRect().top + scrollTop) - trade[0].offsetHeight /2){
                this.tradeShow = true
                trade_h2[0].classList.add('animated','fadeIn')
                trade_content[0].classList.add('animated','fadeIn')
                trade_li.forEach(i=>{
                    i.classList.add('animated','zoomIn')
                }) 
            }

            if(scrollTop > (user[0].getBoundingClientRect().top + scrollTop) - user[0].offsetHeight /2){
                this.userShow = true
                user_h2[0].classList.add('animated','fadeIn')
                user_content[0].classList.add('animated','fadeIn')
                user_slide[0].classList.add('animated','bounceInLeft')
            }

            if(scrollTop > (desk[0].getBoundingClientRect().top + scrollTop) - desk[0].offsetHeight /2){
                this.deskShow = true
                desk[0].classList.add('animated','fadeIn')
                slidShow[0].classList.add('animated','zoomIn')
            }

            if(scrollTop > (Case[0].getBoundingClientRect().top + scrollTop) - Case[0].offsetHeight / 1.5){
                this.caseShow = true
                Case_h2[0].classList.add('animated','fadeIn')
                Case_swiper[0].classList.add('animated','fadeInUp')
            }
            
        } 
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll, true);
    },
}
</script>

<style lang="less" scoped>
    .indexStyle {
        .head_img{
            background: url('~@/assets/image/applet/banner.png') no-repeat;
            background-size: 100%;
        }
        .trait{
            height: 1440px;
            .h2{
                padding: 65px 0 20px;
            }
            .content{
                margin-bottom: 50px;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                width: 1200px;
                margin: 0 auto;
                li{
                    background: url('~@/assets/image/applet/rectangle.png') no-repeat;
                    width: 296px;
                    height: 279px;
                    &:hover{
                        background: url('~@/assets/image/applet/rectangle1.png') no-repeat;
                    }
                    img{
                        margin: 45px 0 22px 0;
                    }
                    .title{
                        font-size: 22px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333333;
                        margin-bottom: 15px;
                    }
                    .content{
                        width: 140px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

            }
            .fadeIn{
                animation-delay: .5s !important;
            }
            .zoomIn{
                animation-delay: 1s !important;
            }
        }
        .trade{
            height: 578px;
            background: url('~@/assets/image/applet/banner1.png') no-repeat center / 100%;
            .h2{
                color: #ffffff;
                padding: 65px 0 20px;
            }
            .content{
                color: #ffffff;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 1200px;
                margin: 0 auto;
                li{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100px;
                    margin: 0 50px;
                    margin-top: 40px;
                    p{
                        font-size: 20px;
                        font-family: Source Han Sans CN;
                        font-weight: 300;
                        color: #FFFFFF;
                        margin-top: 5px
                    }
                }
                
            }
        }
        
    }
@media screen and (max-width: 1024px) {
    .indexStyle {
        .head_img{
            height: 110px;
            background: url('~@/assets/image/applet/mobile_banner.png') no-repeat;
            background-size: 100%;
        }
        .trait{
            height: 932px;
            .h2{
                line-height: inherit;
                padding: 25px 0 8px;
            }
            .content{
                margin-bottom: 50px;
            }
            .domain{
                width: 345px;
                margin: 0 auto;
            
            ul{
                width: 100%;
                li:nth-child(3n){
                    margin-right: 0;
                }
                li{
                    width: 110px;
                    height: 130px;
                    background-size: 110px 130px;
                    margin-right: 7.5px;
                    img{
                        max-width: 50%;
                        max-height: 30%;
                        margin: 15px 0 6px 0;
                    }
                    .title{
                        font-size: 13px;
                        margin-bottom: 0;
                    }
                    .content{
                        margin-top: -5px;
                        width: 142px;
                        font-size: 8px;
                        line-height: 20px;
                        transform: scale(0.6);
                        margin-left: -15%;
                    }
                }
            }
            }
        }
        .trade{
            height: 430px;
            background: url('~@/assets/image/applet/mobile_icon1.png') no-repeat;
            background-size: 100% 430px;
            .h2{
                padding: 25px 0 8px;
            }
            .content{
                margin-bottom: 0px
            }
            ul{
                width: 100%;
                justify-content: center;
                align-items: center;
                // li:nth-child(4n){
                //     margin-right: 0;
                // }
                li{
                    width: 50px;
                    margin: 0 20px;
                    margin-top: 15px;
                    img{
                        width: 45px;
                        height: 45px;
                    }
                    p{
                        font-size: 13px;
                        margin-top: 0;
                    }
                }
            }
            
        }
    }
}
@media screen and (min-width: 1025px) {
    .indexStyle {
        margin-top: 90px;
    }

}
</style>